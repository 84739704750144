<template lang="pug">
  .cancellation-fees-tab
    AppOverlayLoader(:state="cancellationFeesLoading")
    .cancellation-fee-details(v-if="isCancellationFeeFormShown")
      Form(
        ref="selectedCancellationFee"
        :cancellation-fee-id="selectedCancellationFeeId"
        @back="hideCancellationFeeForm"
      )
    .cancellation-fees(v-else)
      ActionBar(
        @add-new="showCancellationFeeForm"
        :with-download="false"
      )
      Table(
        :items="cancellationFees",
        :sorting-data="sortingData"
        @select-row="selectCancellationFee"
        @remove-row="removeCancellationFee"
        @sorting="changeSorting"
      )
</template>

<script>
  // misc
  import { appDebounce } from "@/helpers/common"

  // store modules
  import cancellationFeesModule from "@/config/store/company_system/basic_settings/cancellation_fees"

  // mixins
  import withSorting from "@/mixins/withSorting"
  import withStoreModule from "@/mixins/withStoreModule"
  import withConfirmation from "@/mixins/withConfirmation"

  const cancellationFeesMixin = withStoreModule(cancellationFeesModule, {
    name: "cancellationFees",
    readers: {
      cancellationFees: "items",
      cancellationFeesLoading: "loading",
      sortingData: "sorting"
    },
    actions: {
      fetchCancellationFees: "FETCH_ITEMS",
      deleteCancellationFee: "DELETE_ITEM"
    },
    mutations: { setSorting: "SET_SORTING" }
  })

  export default {
    components: {
      Table: () => import("./Table"),
      Form: () => import("./Form"),
      ActionBar: () => import("../../ActionBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader")
    },

    mixins: [cancellationFeesMixin, withSorting, withConfirmation],

    data() {
      return {
        isCancellationFeeFormShown: false,
        selectedCancellationFeeId: null
      }
    },

    created() {
      this.debouncedFetchCancellationFees = appDebounce(() => this.fetchCancellationFees())
    },

    mounted() {
      this.fetchCancellationFees()
    },

    beforeRouteLeave(to, _from, next) {
      this.beforeRouteLeaveHandler({ to, next, isChanges: this.$refs.selectedCancellationFee?.hasUnsavedChanges })
    },

    methods: {
      hideCancellationFeeForm() {
        this.isCancellationFeeFormShown = false
        this.selectedCancellationFeeId = null
        this.fetchCancellationFees()
      },

      showCancellationFeeForm() {
        this.isCancellationFeeFormShown = true
      },

      selectCancellationFee({ id }) {
        this.selectedCancellationFeeId = id
        this.isCancellationFeeFormShown = true
      },

      changeSorting(sorting) {
        this.setSorting(sorting)
        this.debouncedFetchCancellationFees()
      },

      removeCancellationFee({ id }) {
        this.$confirm({
          title: this.$t("company_system.basic_settings.delete_cancellation_fee_confirmation"),
          resolve: {
            handler: () => {
              this.deleteCancellationFee(id)
            }
          }
        })
      }
    }
  }
</script>

<style lang="sass" scoped>
  .cancellation-fees-tab
    position: relative
</style>
